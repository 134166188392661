<template>
  <v-app-bar app class="boxShawdom" style="background: white; height: 100px">
    <v-overlay :value="getOver"></v-overlay>
    <v-app-bar-nav-icon
      class="hidden-lg-and-up"
      @click="setDraw"
    ></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-avatar>
      <img src="../../assets/dashboard/user.png" alt="Photo user" />
    </v-avatar>
    <div v-if="getUser">
      <p class="mb-1 mt-5 mx-5" style="font-size: 14px; font-weight: bold">
        {{ getUser.full_name ? getUser.full_name : "" }}
      </p>
      <p class="mt-1" style="font-size: 12px; text-align: center">
        {{ getUser.user_role.name ? getUser.user_role.name : "" }}
      </p>
    </div>
    <v-btn icon>
      <v-icon @click="signOut">mdi-export</v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "Navbar",
  computed: {
    ...mapGetters("auth", ["getUser"]),
    ...mapGetters(["getOver"]),
  },
  methods: {
    ...mapActions(["setDraw"]),
    ...mapActions("auth", ["logout"]),
    async signOut() {
      if (this.getUser.user_provider.provider == "google") {
        const response = await this.$gAuth.signOut();
      }
      await this.logout();
    },
  },
  created() {},
};
</script>

<style>
</style>
