<template>
  <v-app>
    <v-overlay :value="getOver">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <Navbar></Navbar>
    <Sidebar></Sidebar>
    <v-main class="fadeIn">
      <v-container>
        <div class="pt-16 px-md-16">
          <v-row row wrap>
            <v-col>
              <v-alert v-model="alert" color="orange" text type="info">
                <v-row class="flex justify-md-space-between">
                  <v-col cols="12" md="7">
                    <p class="title">
                      Su evento comenzará en {{ numberHours }} horas
                    </p>
                  </v-col>
                  <v-col cols="12" md="auto" class="float-right">
                    <v-btn @click="closeAlertEvent" depressed dark color="error"
                      >No volver a mostrar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
            <v-col cols="12" v-if="showAlert">
              <v-alert v-model="showAlert" color="orange" text type="info">
                <v-row align="center" class="flex justify-md-space-between">
                  <v-col class="grow" cols="12" md="6">
                    <p class="title">
                      Su menbrecia finalizara en {{ numberDays }} dias
                    </p>
                  </v-col>
                  <v-col class="shrink text-right" cols="12" md="3">
                    <v-btn @click="closeAlert" depressed dark color="error"
                      >No volver a mostrar</v-btn
                    >
                  </v-col>
                </v-row>
              </v-alert>
            </v-col>
          </v-row>
          <slot />
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "../components/dashboard/Navbar.vue";
import Sidebar from "../components/dashboard/Sidebar.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "DashboardLayout",
  data() {
    return {
      alert: false,
      message: "",
      showAlert: false,
      numberDays: 0,
      numberHours: 0,
    };
  },
  components: {
    Navbar,
    Sidebar,
  },
  async created() {
    // console.log(this.getEventsToday);

    // const event_order = this.getEventsToday.sort((a, b) => {
    //   return (
    //     new Date(moment(a.date_start).format("HH:mm:ss")) >
    //     new Date(moment(b.date_start).format("HH:mm:ss"))
    //   );
    // });

    // console.log(event_order);
    // console.log(this.getEventsToday);

    // this.getEventsToday.forEach((event) => {
    //   console.log(event.date_start);
    //   console.log(moment());
    //   console.log(moment.utc(event.date_start), "horas");
    //   console.log(moment().diff(event.date_start, "hours"), "horas");
    //   if (
    //     parseInt(moment().diff(event.date_start, "hours")) <= 3 &&
    //     !localStorage.getItem("view_event")
    //   ) {
    //     this.alert = true;
    //     this.numberHours = parseInt(moment().diff(event.date_start, "hours"));
    //   }
    // });

    this.setOver(false);
    // console.log(this.getMembership);
    if (this.getMembership) {
      const start_now = moment(moment().format("YYYY-MM-DD"));
      const end_date = moment(
        moment(this.getMembership.end_date).format("YYYY-MM-DD")
      );
      const diff_days = parseInt(end_date.diff(start_now, "days"));
      // console.log(diff_days);
      if (diff_days >= 1 && diff_days <= 3) {
        this.numberDays = Math.round(end_date.diff(start_now, "days"));
        this.showAlert = true;
      } else {
        this.showAlert = false;
      }

      // if (
      //   start_now.isAfter(moment(this.getMembership.end_date).add(-3, "day")) &&
      //   start_now.isAfter(moment(this.getMembership.end_date).add(-1, "day")) &&
      //   !localStorage.getItem("view_membreship")
      // ) {
      //   this.numberDays = Math.round(end_date.diff(start_now, "days", true));
      //   this.showAlert = true;
      // } else {
      //   this.showAlert = false;
      // }
    }

    await this.getEventsByUser();
  },
  computed: {
    ...mapGetters(["getOver"]),
    ...mapGetters("membership", ["getMembership"]),
    ...mapGetters("calendar", ["getEventsToday"]),
  },
  methods: {
    ...mapActions(["setOver"]),
    ...mapActions("membership", [
      "setIsSuccess",
      "getMyMembership",
      "calcelMyMembership",
    ]),
    ...mapActions("calendar", ["getEventsByUser", "setErrors"]),

    closeAlert() {
      localStorage.setItem("view_membreship", true);
      this.showAlert = false;
      console.log("cerrar");
    },
    closeAlertEvent() {
      localStorage.setItem("view_event", true);
      this.alert = false;
      console.log("cerrar");
    },
  },
};
</script>

<style >
@import url("../assets/dashboard/css/global-styles.css");
/* * {
  border: 1px solid red;
} */
</style>