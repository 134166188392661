<template>
  <v-navigation-drawer width="280" :value="getDraw" class="boxShawdom" app>
    <v-overlay :value="getOver"></v-overlay>
    <v-list
      class="boxShawdom d-flex align-center"
      style="height: 100px; width: 280px"
    >
      <v-list-item class="d-flex justify-center">
        <v-list-item-icon>
          <img
            @click="$router.push({ name: 'Home' })"
            src="../../assets/dashboard/logo.png"
            width="200px"
            class="logo_img"
          />
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-list id="dash">
      <router-link :to="{ name: 'MyEventsToday' }" class="link">
        <v-list-group
          :value="true"
          prepend-icon="fas fa-home"
          class="primary--text"
        >
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title
                class="secondary--text"
                style="font-weight: 500"
                >Dashboard</v-list-item-title
              >
            </v-list-item-content>
          </template>
        </v-list-group>
      </router-link>
    </v-list>
    <v-list>
      <v-list-group
        :value="true"
        prepend-icon="fas fa-users"
        class="primary--text"
      >
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title class="secondary--text" style="font-weight: 500"
              >Enfoque laboral</v-list-item-title
            >
          </v-list-item-content>
        </template>
        <!--          <router-link :to="`/users`" class="link">-->
        <!--            <v-list-item link class="ml-3">-->
        <!--              <v-list-item-icon>-->
        <!--                <v-icon style="font-size: 10px">fas fa-circle-notch</v-icon>-->
        <!--              </v-list-item-icon>-->
        <!--              <v-list-item-title>Usuarios</v-list-item-title>-->
        <!--            </v-list-item>-->
        <!--          </router-link>-->

        <v-list-item
          link
          v-for="(link, index) in links"
          :key="index"
          :to="{ name: link.redirecTo }"
        >
          <v-list-item-icon>
            <v-icon style="font-size: 10px">fas fa-circle-notch</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ link.title }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
    </v-list>
    <!--    <v-list id="inspiration">-->
    <!--      <router-link :to="`/inspiration`" class="link">-->
    <!--        <v-list-group-->
    <!--            :value="true"-->
    <!--            prepend-icon="fas fa-lightbulb"-->
    <!--            class="primary&#45;&#45;text"-->
    <!--        >-->
    <!--          <template v-slot:activator>-->
    <!--            <v-list-item-content>-->
    <!--              <v-list-item-title-->
    <!--                  class="secondary&#45;&#45;text"-->
    <!--                  style="font-weight: 500"-->
    <!--              >Inspiración</v-list-item-title-->
    <!--              >-->
    <!--            </v-list-item-content>-->
    <!--          </template>-->
    <!--        </v-list-group>-->
    <!--      </router-link>-->
    <!--    </v-list>-->
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SideBar",
  data() {
    return {
      links: [
        { title: "Mis datos", redirecTo: "MyProfile" },
        { title: "Video llamadas", redirecTo: "MyCalendary" },
        { title: "Iniciar Chat", redirecTo: "Chat" },
        { title: "Mi membresia", redirecTo: "Mymembership" },
        { title: "Mis pagos", redirecTo: "MyPayments" },
        { title: "Mis documentos", redirecTo: "MyDocuments" },
        { title: "Asesoria Express", redirecTo: "AdvisoryExpress" },
      ],
    };
  },

  computed: {
    ...mapGetters(["getDraw", "getOver"]),
    ...mapGetters("auth", ["getUser"]),
  },
};
</script>

<style scoped>
* {
  font-size: 14px;
}
.link {
  text-decoration: none !important;
}
.logo_img {
  cursor: pointer;
}
</style>
